// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("custom/layout");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/actiontext");
Turbolinks.setProgressBarDelay(1);

require("autocomplete");
require("init_mdbootstrap");
require("init_inputmask");
require("fix_turbolink_anchor");
require("reviews_modal");
require("order_review");
require("bulk_edit_orders_modal");
require("bulk_edit_orders_status_modal");
require("bulk_edit_inspection_fields");
require("instant_booking");
require("simple_instant_booking");
require("admin/orders");
require("bulk_send_to_automated_scheduling");

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
