$( document ).on('turbolinks:load', function() {
    $("form").find(':input').each(function(ix,el)
    {
        if ($(el).parent().hasClass('md-form')) {
            el.focus();
        }
    });

    // Focus above has the issue of scrolling down... so going back to the top...
    window.scrollTo(0,0);

    document.activeElement.blur();
});