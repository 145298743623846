$(document).on('turbolinks:load', function () {
    // Prevent erroneous double-clicks from generating two or more orders in
    // the system
    $("button[type=submit]").closest('form').on('submit', function(ev) {
        $(ev.target).find('button[type=submit]').attr('disabled', true);
    });

    const contractorAvailabilities = {};
    const contractorLastStartdate = {};
    const contractorInspectionTypes = {};

    $(".instant-booking-availability").each(function () {
        let contractorId = $(this).data('contractor-id');

        contractorAvailabilities[contractorId] = {};
        contractorInspectionTypes[contractorId] = $(this).data('inspection-types');

        let startDate;
        let endDate;
        startDate = moment().startOf('week');
        endDate = startDate.clone().add(13, 'days');

        loadContractorCalendar(contractorId, startDate, endDate, true);
    });

    $(".show-month").click(function (e) {
        e.preventDefault();

        let contractorId = $(this).parent().data('contractor-id');

        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        if (availabilityDiv.find('.loading-instant-booking-contractors').is(":visible")) {
            return
        }

        let beginningMonth = moment().startOf('month');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');
        availabilityDiv.find('.calendar-times').hide();
        availabilityDiv.find('.calendar-month-arrows').show();

        loadContractorCalendar(contractorId, beginningMonth, endOfMonth);

        $(this).remove();
    });

    $(".next-month").click(function (e) {
        e.preventDefault();

        let contractorId = $(this).parents(".instant-booking-availability").data('contractor-id');

        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        if (availabilityDiv.find('.loading-instant-booking-contractors').is(":visible")) {
            return
        }

        let beginningMonth = contractorLastStartdate[contractorId].clone().add(1, 'M');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');

        $(this).parent().find('.calendar-month-arrows').show();

        loadContractorCalendar(contractorId, beginningMonth, endOfMonth);

    });

    $(".prev-month").click(function (e) {
        e.preventDefault();

        let contractorId = $(this).parents(".instant-booking-availability").data('contractor-id');

        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        if (availabilityDiv.find('.loading-instant-booking-contractors').is(":visible")) {
            return
        }

        let beginningMonth = contractorLastStartdate[contractorId].clone().subtract(1, 'M');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');

        $(this).parent().find('.calendar-month-arrows').show();

        loadContractorCalendar(contractorId, beginningMonth, endOfMonth);
    });

    $(".next-week").click(function (e) {
        e.preventDefault();

        let contractorId = $(this).parents(".instant-booking-availability").data('contractor-id');

        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        if (availabilityDiv.find('.loading-instant-booking-contractors').is(":visible")) {
            return
        }

        let beginningWeek = contractorLastStartdate[contractorId].clone().add(1, 'W').startOf('week');
        let endOfWeek = beginningWeek.clone().add(1, 'W').subtract(1, 'days');

        $(this).parent().children('.prev-week').prop("disabled",true);

        loadContractorCalendar(contractorId, beginningWeek, endOfWeek);
    });

    $(".prev-week").click(function (e) {
        e.preventDefault();

        let contractorId = $(this).parents(".instant-booking-availability").data('contractor-id');

        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        if (availabilityDiv.find('.loading-instant-booking-contractors').is(":visible")) {
            return;
        }

        let beginningWeek = contractorLastStartdate[contractorId].clone().subtract(1, 'W').startOf('week');
        let endOfWeek = beginningWeek.clone().add(1, 'W').subtract(1, 'days');

        if (beginningWeek < new Date()) {
            $(this).prop("disabled",true);
        }

        if (endOfWeek.clone().endOf('day') < new Date()) {
            return;
        }

        loadContractorCalendar(contractorId, beginningWeek, endOfWeek);
    });

    function loadContractorCalendar(contractorId, startDate, endDate, firstLoad) {
        let availabilityDiv = $(`.instant-booking-availability[data-contractor-id='${contractorId}']`);
        let calendarDiv = availabilityDiv.find('.instant-booking-calendar ');
        let loadingSpinner = availabilityDiv.find('.loading-instant-booking-contractors');
        let calendarMonthTitle = availabilityDiv.find('.calendar-month-title');

        let orderToken = availabilityDiv.data('order-token');

        calendarDiv.find('.calendar-day').remove();

        loadingSpinner.show();
        calendarMonthTitle.html(startDate.format('MMMM'));
        contractorLastStartdate[contractorId] = startDate.clone();
        $.ajax({
            url: "/order_flows/" + orderToken + "/instant_booking_contractor_availability",
            data: {
                contractor_id: contractorId,
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            },
            success: (data) => {
                loadingSpinner.hide();


                for(let i=0; i < startDate.diff(startDate.clone().startOf('week'), 'days'); i++) {
                    calendarDiv.append($('<button class="calendar-day"/>'));
                }

                let anyAvailabilities = false;

                for (let m = startDate; m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
                    let dailyAvailabilities = data[m.format("YYYY-MM-DD")];
                    contractorAvailabilities[contractorId][m.format("YYYY-MM-DD")] = dailyAvailabilities;

                    let hasAvailabilities = dailyAvailabilities !== undefined && dailyAvailabilities.length != 0;
                    if (hasAvailabilities) {
                        anyAvailabilities = true;
                    }

                    let r= $('<button class="calendar-day"><span class="calendar-day-circle">' + m.date() + '</span></button>');
                    if (m.isSame(new Date(), "day")) {
                        r.addClass('today');
                    }
                    if (hasAvailabilities) {
                        r.addClass('calendar-day-with-availability');
                        r.click(calendarDaySelected);
                    }
                    r.data('date', m.format("YYYY-MM-DD"));

                    calendarDiv.append(r);
                }

                if (firstLoad && !anyAvailabilities) {
                    $(".next-week").trigger('click');
                }
            }
        });
    }

    function calendarDaySelected(e) {
        e.preventDefault();

        let availabilityDiv = $(this).parents(".instant-booking-availability");
        let contractorId = availabilityDiv.data('contractor-id');
        let dateStr = $(this).data('date');

        availabilityDiv.find('.calendar-day-with-availability').removeClass('calendar-day-with-availability-selected');
        $(this).addClass('calendar-day-with-availability-selected');

        let timesDiv = availabilityDiv.find('.calendar-times');
        timesDiv.html('');

        let dailyAvailabilities = contractorAvailabilities[contractorId][dateStr];

        let r = $('<div class="row"/>');

        dailyAvailabilities.forEach(function (dailyAvailability) {
            let c = $('<div class="col-6"/>' + moment(dailyAvailability).format('LT') + '');

            let f = $("<form></form>");
            f.attr('method', 'POST');

            f.append(`<input type="hidden" name="_method" value="put">`);
            f.append(`<input type="hidden" name="authenticity_token" value="${AUTH_TOKEN}">`);
            f.append(`<input type="hidden" name="contractor_id" value="${contractorId}">`);
            f.append(`<input type="hidden" name="time" value="${dailyAvailability}">`);
            contractorInspectionTypes[contractorId].forEach( (inspectionType) => {
                f.append(`<input type="hidden" name="inspection_types[]" value="${inspectionType}">`);
            });
            f.append(`<button class="btn btn-outline-primary calendar-time px-3">${moment(dailyAvailability).format('LT')}</button>`);

            c.append(f);
            r.append(c);
        });

        timesDiv.append(r);
        timesDiv.show();
    }
});

