function initializeAutocomplete(id) {
    var element = document.getElementById(id);
    if (element) {
        var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'], componentRestrictions: {country: ['us', 'ca']} });
        google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);

        var observerHack = new MutationObserver(function() {
            observerHack.disconnect();
            $("#autocomplete_address").attr("autocomplete", "off");
        });

        observerHack.observe(element, {
            attributes: true,
            attributeFilter: ['autocomplete']
        });
    }
}
function initializeRegionAutocomplete(id) {
    var element = document.getElementById(id);
    if (element) {
        var autocomplete = new google.maps.places.Autocomplete(
            element, { types: ['(regions)'], componentRestrictions: {country: ['us', 'ca']} }
            );

        var observerHack = new MutationObserver(function() {
            observerHack.disconnect();
            $(element).attr("autocomplete", "off");
        });

        observerHack.observe(element, {
            attributes: true,
            attributeFilter: ['autocomplete']
        });
    }
}

function onPlaceChanged() {
    var place = this.getPlace();

    // console.log(place);  // Uncomment this line to view the full object returned by Google API.

    for (var i in place.address_components) {
        var component = place.address_components[i];
        for (var j in component.types) {  // Some types are ["country", "political"]
            var type_element = document.getElementById(component.types[j]);
            if (!type_element && component.types[j] === 'postal_code') {
                type_element = document.getElementById("zip_code");
            }
            if (type_element) {
                type_element.value = component.long_name;
            }
        }
    }
}

$(document).on('turbolinks:load', function() {
    google.maps.event.addDomListener(window, 'turbolinks:load', function() {
        initializeAutocomplete('autocomplete_address');
        initializeRegionAutocomplete('autocomplete_region');
    });
});

if (typeof google !== 'undefined') {
    google.maps.event.addDomListener(window, 'load', function() {
        initializeAutocomplete('autocomplete_address');
        initializeRegionAutocomplete('autocomplete_region');
    });
}
