$(document).on('turbolinks:load', function () {
  const checkIfOrdersSelected = () => {
    const isChecked = $('#admin-orders input.order_checkbox:checked').length;
    $('#bulkEditButton').prop('disabled', !isChecked);
    $('#bulkEditStatusButton').prop('disabled', !isChecked);

    let automatedSchedulingAvailableOrderCount = 0;
    $('#admin-orders input.order_checkbox:checked').each((_, item) =>
      automatedSchedulingAvailableOrderCount += $(item.parentElement.parentElement).find(".js-automated-scheduling-available").length
    );
    $('#bulkSendToAutomatedSchedulingButton').prop('disabled', automatedSchedulingAvailableOrderCount === 0);
  }
  checkIfOrdersSelected();
  $('#admin-orders .order_checkbox').on('change', checkIfOrdersSelected);
  $('#admin-orders #orders_select_all').on('click', checkIfOrdersSelected);
});

$(document).on('click', '.copy-to-clipboard', function(e) {
  var $$ = $(this);
  var notificationDiv = $("<div class='alert alert-success action-alert'>Copied to clipboard!</div>");

  var val = $$.data('copy-to-clipboard-value') || $$.text().trim();
  navigator.clipboard.writeText(val);

  $("body").append(notificationDiv);

  notificationDiv.css("display", "block");
  notificationDiv.css('left', e.pageX);
  notificationDiv.css('top', e.pageY);
  notificationDiv.fadeOut(4000, 'easeInOutQuart', function() {
    $(this).remove();
  });

  return false;
});

$(document).on('click', '.copy-to-clipboard-button', function() {
  var fieldId = $(this).attr('for');

  var fieldValue = $(`#${fieldId}`).val()

  navigator.clipboard.writeText(fieldValue.trim());

  $(this).find('.copy-to-clipboard-icon').removeClass('fa-copy').addClass('fa-check');
  $(this).addClass('copied')
})
