// clone the inspection fields form 2 extra times when the +add inspection is clicked
var timesClicked = 0;

$(document).on('click','#addInspectionForm',function(){
    timesClicked++;

    // clone inspection-fields element on each click
    $('.inspection-form-container').append( `&nbsp; <p>Inspection ${timesClicked+1}</p>` );
    $('.inspection-fields').first().find('.schedule-inspection-fields input').prop('disabled', false);
    $('.inspection-fields').last().clone().addClass('cloned').appendTo('.inspection-form-container');

    // for each cloned inspection-fields, update the date element id and data-target
    var availabilityDateElement = $('.inspection-form-container').find(".availability-date").last()
    var clonedAvailabilityId = 'availability' + (timesClicked+1)

    availabilityDateElement.attr('id', clonedAvailabilityId);
    availabilityDateElement.find('.datetimepicker-input').attr('data-target', '#' + clonedAvailabilityId)
    availabilityDateElement.find('.input-group-append').attr('data-target', '#' + clonedAvailabilityId)

    // we need to pass the datepicker attributes again for the cloned ones...
    $(function () {
      $('.availability-date').datetimepicker({
          sideBySide: true,
          stepping: 15
      });
    });

    if (timesClicked==2) {
      // hide +add inspection button
      $(this).css({'visibility':'hidden'});
    }
})
