$( document ).on('turbolinks:load', function() {
    $(".openContractorReviewsModalLink").on('click', function(event) {
        $('#loading-reviews').show();
        $('#reviewsModalBody').hide();
        $('#modalReviews').modal('show');

        var contractorId = $(this).data('contractor');

        var contractorName = $(this).data('contractorName')
        $('#modalReviewsTitle').html(contractorName + " Reviews")

        $.ajax({
            url: "/contractors/" + contractorId + "/reviews_modal_content",
            success: function() {
                $('#loading-reviews').hide();
                $('#reviewsModalBody').show();

                $('#reviewsCarousel').slick({
                    dots: true,
                    infinite: false,
                });

            }
        })
    });
});