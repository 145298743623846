$(document).on('turbolinks:load', function () {
  $('#bulk-edit-form').submit(function (eventObj) {
    $('.order_checkbox:checkbox:checked').each(function () {
      $('#bulk-edit-form').append('<input type="hidden" name="order_ids[]" value="' + $(this).val() + '" /> ');
    });
    return true;
  });
  $('#editOrdersModal #bulk-edit-form #availability1').on('change.datetimepicker', ({ date }) => {
    if ($('#editOrdersModal #bulk-edit-form .schedule-inspection-fields').length === 1) {
      if (!!date) {
        $('#editOrdersModal #bulk-edit-form .schedule-inspection-fields')
          .first()
          .find('input')
          .prop('disabled', false);
      } else {
        $('#editOrdersModal #bulk-edit-form .schedule-inspection-fields')
          .first()
          .find('input')
          .prop('disabled', true);
      }
    }

  })
});
