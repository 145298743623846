$(document).on('turbolinks:load', function () {
    const contractorsAvailabilities = {};
    const contractorIds = [];
    const holidays = [];
    let fixedSchedule = false;
    let lastStartDate = undefined;
    let inspectionType = undefined;
    let earliestBookingTimestamp = undefined;
    let timezone = undefined

    const availabilityDiv = $(".simple-instant-booking-availability");
    const calendarDiv = availabilityDiv.find('.instant-booking-calendar');
    const loadingSpinner = availabilityDiv.find('.loading-instant-booking-contractors');
    const calendarMonthTitle = availabilityDiv.find('.calendar-month-title');

    $(".simple-instant-booking-availability").each(function () {
        let lastSunday = moment().startOf('week');
        let nextSaturday = lastSunday.clone().add(13, 'days');

        if (!$(this).data('fixed-schedule')) {
            contractorIds.push(...$(this).data('contractor-ids'));
            inspectionType = $(this).data('inspection-type');

            loadContractorsCalendar(lastSunday, nextSaturday);
        } else {
            earliestBookingTimestamp = $(this).data('earliest-booking-timestamp');
            timezone = $(this).data('timezone');

            loadFixedScheduleCalendar(lastSunday, nextSaturday);
            fixedSchedule = true;
        }

    });

    $(".simple-show-month").click(function (e) {
        e.preventDefault();

        if (loadingSpinner.is(":visible")){
            return;
        }

        let beginningMonth = moment().startOf('month');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');

        $('.calendar-times').hide();
        $('.calendar-month-arrows').show();

        if (fixedSchedule) {
            loadFixedScheduleCalendar(beginningMonth, endOfMonth);
        } else {
            loadContractorsCalendar(beginningMonth, endOfMonth);
        }

        $(this).remove();
    });

    $(".simple-next-month").click(function (e) {
        e.preventDefault();

        if (loadingSpinner.is(":visible")){
            return;
        }

        let beginningMonth = lastStartDate.clone().add(1, 'M');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');

        $(this).parent().find('.calendar-month-arrows').show();

        if (fixedSchedule) {
            loadFixedScheduleCalendar(beginningMonth, endOfMonth);
        } else {
            loadContractorsCalendar(beginningMonth, endOfMonth);
        }
    });

    $(".simple-prev-month").click(function (e) {
        e.preventDefault();

        if (loadingSpinner.is(":visible")){
            return;
        }

        let beginningMonth = lastStartDate.clone().subtract(1, 'M');
        let endOfMonth = beginningMonth.clone().add(1, 'M').subtract(1, 'days');

        $(this).parent().find('.calendar-month-arrows').show();

        if (fixedSchedule) {
            loadFixedScheduleCalendar(beginningMonth, endOfMonth);
        } else {
            loadContractorsCalendar(beginningMonth, endOfMonth);
        }
    });

    function loadContractorsCalendar(startDate, endDate) {
        let orderToken = availabilityDiv.data('order-token');

        calendarDiv.find('.calendar-day').remove();
        loadingSpinner.show();
        calendarMonthTitle.html(startDate.format('MMMM'));
        lastStartDate = startDate.clone();

        let promises = [];
        contractorIds.forEach( (contractorId) => {
            promises.push(loadContractorAvailability(orderToken, contractorId, startDate, endDate));
        });

        Promise.all(promises).then(function () {
            displayCalendar(startDate, endDate);
        });
    }

    function loadFixedScheduleCalendar(startDate, endDate) {
        calendarDiv.find('.calendar-day').remove();
        calendarMonthTitle.html(startDate.format('MMMM'));
        lastStartDate = startDate.clone();

        holidays.push(...availabilityDiv.data('holidays'));

        let orderToken = availabilityDiv.data('order-token');
        const currentTime = moment();

        contractorsAvailabilities[true] = {};
        for (let m = startDate.clone().tz(timezone); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
            if (holidays.includes(m.format("YYYY-MM-DD"))) continue;

            contractorsAvailabilities[true][m.format("YYYY-MM-DD")] = [];
            [9, 12, 15].forEach((hour) => {
                m.set('hour', hour);
                if (m.unix() > earliestBookingTimestamp) {
                    contractorsAvailabilities[true][m.format("YYYY-MM-DD")].push(m.format("MM/DD/YYYY h:mm A"));
                }
            })
        }

        displayCalendar(startDate, endDate);
    }

    function loadContractorAvailability(orderToken, contractorId, startDate, endDate) {
        contractorsAvailabilities[contractorId] = {};

        return $.ajax({
            url: "/order_flows/" + orderToken + "/instant_booking_contractor_availability",
            data: {
                contractor_id: contractorId,
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            },
            success: (data) => {
                for (let m = startDate.clone(); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
                    let dailyAvailabilities = data[m.format("YYYY-MM-DD")];
                    contractorsAvailabilities[contractorId][m.format("YYYY-MM-DD")] = dailyAvailabilities;
                }
            }
        });
    }

    function displayCalendar(startDate, endDate) {
        loadingSpinner.hide();

        for(let i=0; i < startDate.diff(startDate.startOf('week'), 'days'); i++) {
            calendarDiv.append($('<button class="calendar-day"/>'));
        }

        let allAvailableDates = [];
        for (let m = startDate.clone(); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
            Object.keys(contractorsAvailabilities).forEach(function (contractorId) {
                let contractorAvailabilities = contractorsAvailabilities[contractorId];
                let dailyAvailabilities = contractorAvailabilities[m.format("YYYY-MM-DD")];

                let hasAvailabilities = dailyAvailabilities !== undefined && dailyAvailabilities.length != 0;

                if (hasAvailabilities) {
                    if (allAvailableDates[m.format("YYYY-MM-DD")] === undefined) {
                        allAvailableDates.push(m.format("YYYY-MM-DD"))
                    }
                }
            });
        }

        for (let m = startDate.clone(); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
            let hasAvailabilities = allAvailableDates.includes(m.format("YYYY-MM-DD"));

            let r= $('<button class="calendar-day"><span class="calendar-day-circle">' + m.date() + '</span></button>');
            if (m.isSame(new Date(), "day")) {
                r.addClass('today');
            }
            if (hasAvailabilities) {
                r.addClass('calendar-day-with-availability');
                r.click(calendarDaySelected);
            }
            r.data('date', m.format("YYYY-MM-DD"));

            calendarDiv.append(r);
        }
    }

    function calendarDaySelected(e) {
        e.preventDefault();

        let dateStr = $(this).data('date');

        $('.calendar-day-with-availability').removeClass('calendar-day-with-availability-selected');
        $(this).addClass('calendar-day-with-availability-selected');

        let timesDiv = $('.simple-calendar-times');
        timesDiv.html('');

        let allDailyAvailabilities = {};
        Object.keys(contractorsAvailabilities).forEach(function (contractorId){
            let contractorAvailabilities = contractorsAvailabilities[contractorId];
            let dailyAvailabilities = contractorAvailabilities[dateStr];

            if (dailyAvailabilities === undefined) {
                return;
            }

            dailyAvailabilities.forEach(function (dailyAvailability) {
                if (allDailyAvailabilities[dailyAvailability] === undefined) {
                    allDailyAvailabilities[dailyAvailability] = []
                }
                allDailyAvailabilities[dailyAvailability].push(contractorId)
            });
        });

        let r = $('<div class="row"/>');

        Object.keys(allDailyAvailabilities).sort(function (time1, time2) {
            if (moment(time1) < moment(time2)) {
                return -1;
            }
            if (moment(time1) > moment(time2)) {
                return 1;
            }
            return 0;
        }).forEach(function (dailyAvailability) {
            let contractorIds = allDailyAvailabilities[dailyAvailability];

            let c = $('<div class="col-6"/>' + moment(dailyAvailability).format('LT') + '');

            let f = $("<form></form>");
            f.attr('method', 'POST');

            f.append(`<input type="hidden" name="_method" value="put">`);
            f.append(`<input type="hidden" name="authenticity_token" value="${AUTH_TOKEN}">`);
            contractorIds.forEach( (contractorId) => {
                f.append(`<input type="hidden" name="contractor_ids[]" value="${contractorId}">`);
            });
            f.append(`<input type="hidden" name="inspection_type" value="${inspectionType}">`);
            f.append(`<input type="hidden" name="time" value="${dailyAvailability}">`);
            f.append(`<button class="btn btn-outline-primary calendar-time px-3">${moment(dailyAvailability).format('LT')}</button>`);

            c.append(f);
            r.append(c);
        });

        timesDiv.append(r);
        timesDiv.show();
    }
});

